import styled from "styled-components";

export const ParallaxContainer = styled.div`
  height: 55vh;
  

  @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 55vh;
       background: #ffa166 !important;

  }

  @media all and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    height: 38vh;
    background: #ffa166 !important;


`;

export const ParallaxText = styled.h3`
  position: absolute;
  top: 50%;
  left: 25%;
  width: 45% !important;
  transform: translate(-50%, -50%);
  text-align: left;
  font-size: 1.2rem;
  font-weight: 510;
  color: 26282C;
  width: 100%;
  margin-bottom: 1rem;

  @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${(props) => props.fontSizeMobile || "0.9375rem"};
 

  @media all and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${(props) => props.fontSizeMobile || "0.9375rem"};
    
  @media all and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 0.60rem;
    position: relative;
    left: 50% !important;
    width: 90% !important;
    
  }

  @media all and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 0.60rem;
    font-weight: 950;
    position: relative;
    left: 50% !important;
    width: 90% !important;

  }

  @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 0.60rem;
    font-weight: 950;
    position: relative;
    left: 50% !important;
    width: 90% !important;

  }

  @media all and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    font-size: 0.70rem;
    font-weight: 1000;
    position: relative;
    left: 50% !important;
    width: 90% !important;
  }
`;
