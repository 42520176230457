import React from "react";
import {
  AboutContent,
  AboutDetailsContainer,
  AboutImage,
  AboutImageContainer,
  StyledAbout,
} from "../styles/About.styled";
import { SectionHeading, SectionTitle } from "../styles/SectionHeading";
import { StyledParagraph } from "../styles/Typography.styled";
import myImage from "../../assets/my-image.png";

const About = () => {
  return (
    <StyledAbout id="about">
      <SectionHeading dark="true" mb="3rem">
        <SectionTitle dark="true">
          About.
        </SectionTitle>
      </SectionHeading>
      <AboutContent>
        <AboutImageContainer>
          <AboutImage src={myImage} />
        </AboutImageContainer>
        <AboutDetailsContainer>
          <StyledParagraph>
          I am:<br/>
            • A creative and self-starting Front End Developer<br/>
            • Experienced in building customer-focused websites<br/>
            • Proficient with CSS Frameworks & JS Libraries<br/>
            • Adept at working in collaborative environments<br/>
            • Solution-driven, with extensive knowledge of UX and end-user psychology<br/>
            • Competing my master’s degree in Human Computer Interaction (HCI) at Salzburg University of Applied Sciences / FH Salzburg.
          </StyledParagraph>
          <StyledParagraph>
            I was honored to intern at CERN Openlab in 2021,
            where I collaborated with the CERN IT Team to
            redefine, redesign, and implement new user interfaces
            for the CERN Search Portal.<br/>
            <br/>The redesigned Portal now delivers an enhanced user
            experience with improved mobile friendliness, through
            implementation & integration of Figma, React.js and
            Bootstrap.
          </StyledParagraph>
        </AboutDetailsContainer>
      </AboutContent>
    </StyledAbout>
  );
};

export default About;
