import React from "react";
import { Parallax } from "react-parallax";
import parralaxImage from "/Users/mehdigolbaz/Desktop/personalBlog/src/assets/bgParallax.png";
import { ParallaxContainer, ParallaxText } from "../styles/Parallax.styled";

const ParallaxComponent = () => {
  return (
    <Parallax bgImage={parralaxImage} strength={-200} style={{ width: "100%" }}>
      <ParallaxContainer>
        <ParallaxText>
               My primary interest in HCI's research areas has shifted to studying and improving human experiences in everyday activities. Moreover, how to serve current technologies to affect their critical activities such as health and cognitive behaviors.<br/>
          <br/>My specialists' goal is to increase awareness of the current and prospective technological deficiencies and prioritize user well-being as the most compelling aspect by getting into practice cutting-edge technologies such as Artificial Intelligence(AI) and Virtual Reality(VR).<br/>
          <br/>Likewise, monitoring user behaviors to analyze and implement a data-driven approach in creating user-centered interactive systems.
        </ParallaxText>
      </ParallaxContainer>
    </Parallax>
  );
};

export default ParallaxComponent;
